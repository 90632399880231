import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { FilledButtonComponent } from '@fc-shared/ui/buttons/filled-button.component';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { StrokedButtonComponent } from '@fc-shared/ui/buttons/stroked-button.component';
import { DialogHeaderComponent } from '@fc-shared/ui/dialog-opener/dialog-header.component';
import { DialogFooterComponent } from '@fc-shared/ui/dialog-opener/dialog-footer.component';
import { DialogContentComponent } from '@fc-shared/ui/dialog-opener/dialog-content.component';

@NgModule({
  imports: [
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    FilledButtonComponent,
    TextButtonComponent,
    StrokedButtonComponent,
    DialogHeaderComponent,
    DialogFooterComponent,
    DialogContentComponent,
  ],
  exports: [ConfirmDialogComponent],
  declarations: [ConfirmDialogComponent],
})
export class ConfirmDialogModule {}
